
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import rule from "./../../constant/Rule";



function EmailConfigAdd(props) {
    var [emailConfig, setEmailConfig] = useState(props.emailConfig);
    const growl = useRef(null);
    const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();


    useEffect(() => {
        reset({ ...emailConfig });
    }, [])

    const handleChange = (e, type) => {
        let val = e.target.value;
        setEmailConfig({ ...emailConfig, [e.target.name]: val });
        return val;
    };

    const save = data => {
        props.saveEmailConfigSettings(emailConfig);

    }


    return (
        <div className="p-grid ">
            <Growl ref={growl} style={{ marginTop: "75px" }} />
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1>Add Config</h1>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-8">
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                          <i className="material-icons">message</i>
                        </span>
                                <span className="md-inputfield">
                                    <Controller
                                        className={errors.emailServer ? "p-error" : ""}
                                        name="emailServer"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig?.emailServer}
                                        control={control}
                                        as={InputText}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                    <label htmlFor="emailServer">Email Server</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.emailServer?.message}
                            </span>
                        </div>

                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="fas fa-key fa-lg"></i>
                                </span>
                                <span className="md-inputfield">
                                    <Controller
                                        className={errors.emailServerPort ? "p-error" : ""}
                                        name="emailServerPort"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig?.emailServerPort}
                                        control={control}
                                        as={InputText}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                    <label htmlFor="emailServerPort">Email Server Port</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.emailServerPort?.message}
                            </span>
                        </div>

                        <div className="p-col-8">
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                        <i className="material-icons">email</i>
                      </span>
                                <span className="md-inputfield">
                                    <Controller
                                        className={errors.senderEmail ? "p-error" : ""}
                                        name="senderEmail"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig?.senderEmail}
                                        control={control}
                                        as={InputText}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                    <label htmlFor="senderEmail">Sender Email</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.senderEmail?.message}
                            </span>

                        </div>

                        <div className="p-col-8">
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                    <i className="fas fa-key fa-lg"></i>
                                </span>
                                <span className="md-inputfield">
                                    <Controller
                                        className={errors.twoFAPasswordForEmail ? "p-error" : ""}
                                        name="twoFAPasswordForEmail"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig.twoFAPasswordForEmail}
                                        control={control}
                                        as={InputText}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                    <label htmlFor="twoFAPasswordForEmail">2FA Password for Email</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.twoFAPasswordForEmail?.message}
                            </span>

                        </div>

                        <div className="p-col-8">
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                        <i className="material-icons">email</i>
                      </span>
                                <span className="md-inputfield">
                                    <Controller
                                        className={errors.helpDeskEmail ? "p-error" : ""}
                                        name="helpDeskEmail"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig.helpDeskEmail}
                                        control={control}
                                        as={InputText}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                    <label htmlFor="helpDeskEmail">Help Desk Email</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.helpDeskEmail?.message}
                            </span>

                        </div>


                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="fas fa-comment fa-lg"></i>
                                </span>
                                <span className="md-inputfield">
                                    <Controller
                                        className={errors.subjectForCustomerEmail ? "p-error" : ""}
                                        name="subjectForCustomerEmail"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig?.subjectForCustomerEmail}
                                        control={control}
                                        as={InputText}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                    <label htmlFor="subjectForCustomerEmail">Subject for Customer Email</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.subjectForCustomerEmail?.message}
                            </span>

                        </div>

                        
                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="fas fa-comment fa-lg"></i>
                                </span>
                                <span className="md-inputfield">
                                    <Controller
                                        className={errors.subjectForVendorEmail ? "p-error" : ""}
                                        name="subjectForVendorEmail"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig?.subjectForVendorEmail}
                                        control={control}
                                        as={InputText}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                    <label htmlFor="subjectForVendorEmail">Subject for Vendor Email</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.senderEmail?.message}
                            </span>

                        </div>

                        <div className="p-col-8">
                        <label style={

{
    color: '#03a9f4', fontWeight: 'normal', fontSize: '12px', marginLeft:'30px'

}

} htmlFor="emailMessageToVendor">Email Message to Customer</label>
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                          <i className="material-icons">message</i>
                        </span>

                                <span className="md-inputfield">

                                    <Controller
                                        className={errors.emailMessageToCustomer ? "p-error" : ""}
                                        name="emailMessageToCustomer"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig.emailMessageToCustomer}
                                        control={control}
                                        as={InputTextarea}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.emailMessageToCustomer?.message}
                            </span>

                        </div>


                       
                        <div className="p-col-8">
                        <label style={

{
    color: '#03a9f4', fontWeight: 'normal', fontSize: '12px', marginLeft:'30px'

}

} htmlFor="emailMessageToVendor">Email Message to Vendor</label>
                            <div className="p-inputgroup">
    
                            <span className="p-inputgroup-addon">
                          <i className="material-icons">message</i>
                        </span>
                                <span className="md-inputfield">
                                   
                                    <Controller
                                        className={errors.emailMessageToVendor ? "p-error" : ""}
                                        name="emailMessageToVendor"
                                        rules={{
                                            ...rule.RULE.requiredRule
                                        }}
                                        value={emailConfig.emailMessageToVendor}
                                        control={control}
                                        as={InputTextarea}
                                        onChange={(e) => handleChange(e[0])}
                                    />
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.emailMessageToVendor?.message}
                            </span>

                        </div>
                    </div>
                </div>
            </div>



            <div className="p-col-12">
                <Button
                    icon="pi pi-check"
                    label="Save"
                    onClick={handleSubmit(save)}
                />
            </div>
        </div>

    );
};

export default withRouter(EmailConfigAdd);
