import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { AppBreadcrumb } from "../../AppBreadcrumb";
import { HttpService } from "../../service/HttpService";
import { checkForError, clean, showMessage } from "../../utility/AppUtility";
import rule from "./../../constant/Rule";
import { InputSwitch } from "primereact/inputswitch";

function GenerateRandomCode(props) {
  var [obj, setObj] = useState({});
  var [vendors, setVendors] = useState([]);
  var [plants, setPlants] = useState([]);
  var [clients, setClients] = useState([]);
  var [products, setProducts] = useState([]);
  var [digitaArray, setDigitaArray] = useState([]);
  var [isDownloadBulk, setDownloadBulk] = useState(false);
  var [micrositeEnable, setMicrositeEnable] = useState(false);
  var [companyName, setCompanyName] = useState();
  const growl = useRef(null);
  const months = [
    { label: "Jan", value: "Jan" },
    { label: "Feb", value: "Feb" },
    { label: "Mar", value: "Mar" },
    { label: "Apr", value: "Apr" },
    { label: "May", value: "May" },
    { label: "Jun", value: "Jun" },
    { label: "Jul", value: "Jul" },
    { label: "Aug", value: "Aug" },
    { label: "Sep", value: "Sep" },
    { label: "Oct", value: "Oct" },
    { label: "Nov", value: "Nov" },
    { label: "Dec", value: "Dec" },
  ];
  const years = [
    { label: "2015", value: "2015" },
    { label: "2016", value: "2016" },
    { label: "2017", value: "2017" },
    { label: "2018", value: "2018" },
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
    { label: "2029", value: "2029" },
    { label: "2030", value: "2030" },
    { label: "2031", value: "2031" },
    { label: "2032", value: "2032" },
    { label: "2033", value: "2033" },
    { label: "2034", value: "2034" },
    { label: "2035", value: "2035" },
    { label: "2036", value: "2036" },
    { label: "2037", value: "2037" },
    { label: "2038", value: "2038" },
    { label: "2039", value: "2039" },
    { label: "2040", value: "2040" },
    { label: "2041", value: "2041" },
    { label: "2042", value: "2042" },
    { label: "2043", value: "2043" },
    { label: "2044", value: "2044" },
    { label: "2045", value: "2045" },
    { label: "2046", value: "2046" },
    { label: "2047", value: "2047" },
    { label: "2048", value: "2048" },
    { label: "2049", value: "2049" },
    { label: "2050", value: "2050" }
  ]

  const digits = [
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
  ];
  const digits2 = [
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
  ];
  const codeTypes = [
    { label: "Alphanumeric", value: "Alphanumeric" },
    { label: "Numeric", value: "Numeric" },
  ];

  const labelSizes = [
    { label: "200 x 100", value: "200 x 100" },
    { label: "200 x 70", value: "200 x 70" },
  ];

  const { handleSubmit, errors, control, setValue, reset } = useForm({
    mode: "onBlur",
  });
  const httpService = new HttpService();

  useEffect(() => {
    getVendor();
    getCustomer();
  }, []);


  const getVendor = () => {
    trackPromise(
      httpService
        .getApi("vendors/company")
        .then((response) => {
          setVendors(response.data);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };
  const getCustomer = () => {

    trackPromise(
      httpService
        .getApi("customers/company?type=Customer")
        .then((response) => {

          const clientData = response.data.content;
          setClients(response.data.content);

          if (clientData.length === 1) {
            setObj({ ...obj, 'client': clientData[0] })
            setMicrositeEnable(clientData[0]?.micrositeEnable)
            getPlant(clientData[0].id, clientData[0]?.micrositeEnable);
          }

        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );


  };
  const getPlant = (id, micrositeEnable) => {

    trackPromise(
      httpService
        .getApi("plants/" + id + "/customer")
        .then((response) => {

          const plantData = response.data;
          setPlants(response.data);
          if (micrositeEnable) {
            setObj({ ...obj, 'plant': plantData[0] })
            getPlantProduct(plantData[0].id);
          }
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };
  const getPlantProduct = (id) => {
    trackPromise(
      httpService
        .getApi("products/" + id + "/plant")
        .then((response) => {
          setProducts(response.data);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  const handleChange = (e, type) => {

    let val = e.target.value;
    if (e.target.name === "codeType") {
      setValue("digit", null, { shouldDirty: true });
      if (val === "Alphanumeric") {
        setDigitaArray(digits);
      } else {
        setDigitaArray(digits2);
      }
    }
    if (e.target.name === "client") {
      setObj({ [e.target.name]: val, plant: null, product: null, year: null });
      setProducts([]);
      setValue("plant", null);
      setValue("product", null);
      setValue("year", null);
      setMicrositeEnable(val?.micrositeEnable)
      getPlant(val.id, val?.micrositeEnable);
    } else if (e.target.name === "plant") {
      getPlantProduct(val.id);
      setObj({ ...obj, [e.target.name]: val, product: null });
      setValue("product", null);
    } else if (e.target.name === "product") {
      setObj({ ...obj, [e.target.name]: val, labelSize: val.labelSize });
      setValue("labelSize", val.labelSize, { shouldDirty: true });
    } else {
      setObj({ ...obj, [e.target.name]: val });
    }

    return val;
  };

  const cancel = () => {
    props.history.push("print_data_list");
  };

  const save = (data) => {

    if (data?.client == undefined) {
      obj.client = clients[0] || {}
    } else {
      obj.client = data?.client
    }

    if (micrositeEnable) {
      const now = new Date();
      const currentMonth = now.toLocaleString('default', { month: 'short' });
      obj.month = currentMonth
      obj.year = now.getFullYear().toString()
      obj.codeType = 'Alphanumeric'
      obj.quantity = 1
      obj.digit = 9
      obj.up = 1
      obj.isMicrosite = true
      obj.plant = plants[0] || {}
    }

    obj = clean(obj);

    if (isDownloadBulk && (obj.product != undefined)) {
      delete (obj.product);
    }

    trackPromise(
      httpService
        .postApi("save/companyInfo", obj)
        .then((response) => {
          if (micrositeEnable && obj.productSerialNumber !== undefined) {
            generateRotoQr(response.data.id, obj.productSerialNumber)
          } else {
            generateFIle(response.data.id);
            cancel();
          }
        })
        .catch((error) => {
          if (checkForError(error)) {
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
          }
        })
    );
  };

  const generateFIle = (id) => {
    trackPromise(
      httpService
        .getApi("gen/file/" + id)
        .then((response) => {
          showMessage(
            "success",
            "Success Message",
            "File will we generated soon",
            growl
          );

          cancel();
        })
        .catch((error) => {
          if (checkForError(error)) {
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
          }
        })
    );
  };


  const generateRotoQr = (companyInfoId, productSerialNumber) => {
    trackPromise(
      httpService
        .getApi("generate/roto-qr?companyInfoId=" + companyInfoId + "&productSerialNumber=" + productSerialNumber)
        .then((response) => {
          cancel();
        })
        .catch((error) => {
          if (checkForError(error)) {
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
          }
        })
    );
  };

  return (
    <>
      <AppBreadcrumb
        breadcrumdItems={[
          { label: "Print Data List", url: "#/print_data_list" },
          { label: "Print Data Add" },
        ]}
      />
      {clients.length > 0 &&

        <div className="p-grid ">
          <Growl ref={growl} style={{ marginTop: "75px" }} />
          <div className="p-col-10">
            <div className="card card-w-title">
              <h1>Print Data Generator</h1>
              {clients.length > 1 &&

                <div className="p-grid p-fluid form-group">
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">perm_identity</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          optionLabel="companyName"
                          optionValue="id"
                          filter
                          filterBy="companyName"
                          className={errors.client ? "p-error" : ""}
                          rules={{ ...rule.RULE.requiredRule }}
                          as={Dropdown}
                          control={control}
                          options={clients}
                          placeholder={"Client"}
                          name="client"
                          value={obj.client}
                          onChange={(e) => handleChange(e[0])}
                        />
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.client?.message}
                    </span>
                  </div>
                </div>
              }

              {micrositeEnable ? (
                <>
                  <div className="p-grid p-fluid form-group">

                    {!isDownloadBulk && (
                      <>
                        <div className="p-col-8">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="material-icons">shop</i>
                            </span>
                            <span className="md-inputfield">
                              <Controller
                                optionLabel="productCode"
                                optionValue="id"
                                filter
                                filterBy="productCode"
                                className={errors.product ? "p-error" : ""}
                                rules={{ ...rule.RULE.requiredRule }}
                                as={Dropdown}
                                control={control}
                                options={products}
                                placeholder={"Product"}
                                name="product"
                                value={obj.product}
                                onChange={(e) => handleChange(e[0])}
                              />
                            </span>
                          </div>
                          <span className="errorSpan merginLeft30">
                            {errors.product?.message}
                          </span>
                        </div>

                        <div className="p-col-8">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="material-icons">account_circle</i>
                            </span>
                            <span className="md-inputfield">
                              <Controller
                                className={errors.productSerialNumber ? "p-error" : ""}
                                name="productSerialNumber"
                                value={obj.productSerialNumber}
                                rules={{
                                  ...rule.RULE.requiredRule,
                                  ...rule.RULE.maxLengthRuleVal25,
                                }}
                                as={InputText}
                                control={control}
                                onChange={(e) => handleChange(e[0])}
                              />
                              <label>Product Sr No.</label>
                            </span>
                          </div>
                          <span className="errorSpan merginLeft30">
                            {errors.productSerialNumber?.message}
                          </span>
                        </div>

                      </>
                    )}



                  </div>
                </>
              ) : (
                <>
                  <div className="p-grid p-fluid form-group">
                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">today</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.month ? "p-error" : ""}
                            filter
                            rules={{ ...rule.RULE.requiredRule }}
                            as={Dropdown}
                            control={control}
                            options={months}
                            placeholder={"Month"}
                            name="month"
                            value={obj.month}
                            onChange={(e) => handleChange(e[0])}
                          />
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.month?.message}
                      </span>
                    </div>
                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">today</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.year ? "p-error" : ""}
                            filter
                            rules={{ ...rule.RULE.requiredRule }}
                            as={Dropdown}
                            control={control}
                            options={years}
                            placeholder={"Year"}
                            name="year"
                            value={obj.year}
                            onChange={(e) => handleChange(e[0])}
                          />
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.year?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">location_city</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            optionLabel="name"
                            optionValue="id"
                            filter
                            filterBy="name"
                            className={errors.plant ? "p-error" : ""}
                            rules={{ ...rule.RULE.requiredRule }}
                            as={Dropdown}
                            control={control}
                            options={plants}
                            placeholder={"Plant"}
                            name="plant"
                            value={obj.plant}
                            onChange={(e) => handleChange(e[0])}
                          />
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.plant?.message}
                      </span>
                    </div>
                    {!isDownloadBulk && (
                      <>
                        <div className="p-col-8">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="material-icons">shop</i>
                            </span>
                            <span className="md-inputfield">
                              <Controller
                                optionLabel="productCode"
                                optionValue="id"
                                filter
                                filterBy="productCode"
                                className={errors.product ? "p-error" : ""}
                                rules={{ ...rule.RULE.requiredRule }}
                                as={Dropdown}
                                control={control}
                                options={products}
                                placeholder={"Product"}
                                name="product"
                                value={obj.product}
                                onChange={(e) => handleChange(e[0])}
                              />
                            </span>
                          </div>
                          <span className="errorSpan merginLeft30">
                            {errors.product?.message}
                          </span>
                        </div>

                        <div className="p-col-8">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="material-icons">
                                photo_size_select_small
                              </i>
                            </span>
                            <span className="md-inputfield">
                              <Controller
                                disabled={true}
                                className={errors.labelSize ? "p-error" : ""}
                                rules={{ ...rule.RULE.requiredRule }}
                                as={InputText}
                                control={control}
                                options={labelSizes}
                                placeholder={"Label Size"}
                                name="labelSize"
                                value={obj.labelSize}
                                onChange={(e) => handleChange(e[0])}
                              />
                            </span>
                          </div>
                          <span className="errorSpan merginLeft30">
                            {errors.labelSize?.message}
                          </span>
                        </div>
                      </>
                    )}
                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">person</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            optionLabel="name"
                            optionValue="id"
                            filter
                            filterBy="name"
                            className={errors.vendor ? "p-error" : ""}
                            rules={{ ...rule.RULE.requiredRule }}
                            as={Dropdown}
                            control={control}
                            options={vendors}
                            placeholder={"Vendor"}
                            name="vendor"
                            value={obj.vendor}
                            onChange={(e) => handleChange(e[0])}
                          />
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.vendor?.message}
                      </span>
                    </div>
                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">person</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            filter
                            className={errors.codeType ? "p-error" : ""}
                            rules={{ ...rule.RULE.requiredRule }}
                            as={Dropdown}
                            control={control}
                            options={codeTypes}
                            placeholder={"Code Type"}
                            name="codeType"
                            value={obj.codeType}
                            onChange={(e) => handleChange(e[0])}
                          />
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.codeType?.message}
                      </span>
                    </div>
                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">person</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            filter
                            className={errors.digit ? "p-error" : ""}
                            rules={{ ...rule.RULE.requiredRule }}
                            as={Dropdown}
                            control={control}
                            options={digitaArray}
                            placeholder={"Digit"}
                            name="digit"
                            value={obj.digit}
                            onChange={(e) => handleChange(e[0])}
                          />
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.digit?.message}
                      </span>
                    </div>

                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">description</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.quantity ? "p-error" : ""}
                            name="quantity"
                            value={obj.quantity}
                            rules={{
                              ...rule.RULE.requiredRule,
                              ...rule.RULE.maxLengthRule7,
                              ...rule.RULE.minRuleVal9,
                            }}
                            as={InputText}
                            control={control}
                            type={"number"}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Quantity</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.quantity?.message}
                      </span>
                    </div>




                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">description</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.up ? "p-error" : ""}
                            name="up"
                            value={obj.up}
                            rules={{
                              ...rule.RULE.requiredRule,
                              ...rule.RULE.maxLengthRule7,
                              ...rule.RULE.minRuleVal1,
                            }}
                            as={InputText}
                            type={"number"}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Up</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.up?.message}
                      </span>
                    </div>
                    <div className="p-col-8">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          <i className="material-icons">description</i>
                        </span>
                        <span className="md-inputfield">
                          <Controller
                            className={errors.remark ? "p-error" : ""}
                            name="remark"
                            value={obj.remark}
                            rules={{
                              ...rule.RULE.maxLengthRuleVal250,
                            }}
                            as={InputText}
                            control={control}
                            onChange={(e) => handleChange(e[0])}
                          />
                          <label>Remark</label>
                        </span>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.remark?.message}
                      </span>
                    </div>
                  </div>

                  <div className="p-grid p-col-10">
                    <div className="p-col-5">
                      <div className="p-inputgroup">
                        <InputSwitch
                          checked={isDownloadBulk}
                          name={"isDownloadBulk"}
                          inputId={isDownloadBulk}
                          ariaLabelledBy={isDownloadBulk}
                          value={isDownloadBulk}
                          onChange={() => setDownloadBulk(!isDownloadBulk)}
                        />
                        <label
                          htmlFor={isDownloadBulk}
                          className="p-checkbox-label merginLeft15"
                        >
                          Download in Bulk
                        </label>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.isDownloadBulk?.message}
                      </span>
                    </div>
                    <div className="p-col-5">
                      <div className="p-inputgroup">
                        <InputSwitch
                          checked={obj.isMicrosite}
                          name={"isMicrosite"}
                          inputId={obj.isMicrosite}
                          ariaLabelledBy={obj.isMicrosite}
                          value={obj.isMicrosite}
                          // onChange={() => { obj.isMicrosite :(!obj.isMicrosite) }
                          onChange={(event) => handleChange(event)}
                        />
                        <label
                          htmlFor={obj.isMicrosite}
                          className="p-checkbox-label merginLeft15"
                        >
                          Microsite Enable
                        </label>
                      </div>
                      <span className="errorSpan merginLeft30">
                        {errors.isMicrosite?.message}
                      </span>
                    </div>
                  </div>
                </>
              )
              }

              <div className="p-col-12">
                <Button
                  icon="pi pi-check"
                  label="Save"
                  onClick={handleSubmit(save)}
                />
                {/* <Button icon="pi-md-clear" label="Clear" className="p-button-secondary" onClick={() => clear()} /> */}
                <Button
                  icon="pi-md-cancel"
                  label="Cancel"
                  className="p-button-secondary"
                  onClick={() => cancel()}
                />
              </div>
              <div className="p-col-8"></div>
            </div>
          </div>
          <div className="p-col-4"></div>
        </div>
      }
    </>
  );
}
export default withRouter(GenerateRandomCode);
